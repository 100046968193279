<template>
  <b-container fluid>
    <a-form-model layout="horizontal" :model="PermissionForm" :rules="{}" ref="rule-Permission">
      <h5 class="text-secondary text-left font-weight-bold mt-3">Asignación de Permisos</h5>
      <hr class="bg-primary">
      <a-row align="middle" justify="center" class="mb-4">
        <a-col :span="24" class="px-2 border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Permisos</h6>
          <hr class="bg-primary">
          <a-form-model-item>
            <a-checkbox-group v-model="PermissionForm.permissions">
              <a-row>
                <a-col :sm="{ span: 24, }" :md="{ span:12}" v-for="option in Permissions" :key="option.name" class="my-1">
                  <a-checkbox :value="option.name" :disabled="!userHasPermission">
                    <small>{{option.display_name}}</small>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model-item>
        </a-col>

        <div class="form-actions mt-0" v-show="!permissionsLoading">
          <responsive-button @ClickEvent="setPermissionsToRole" pill responsive="md" text="Actualizar Permisos" variant="primary"/>
        </div>

      </a-row>
    </a-form-model>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/axios/index'
export default {
  name: 'RolePermissions',
  props: {
    roleName: {
      default: '',
      type: [String],
      required: true,
    },
  },
  computed: {
    ...mapState(['user']),
    user() {
      return this.$store.state.user
    },
    loading() {
      return this.$store.state.user.loading
    },
    userHasPermission() {
      return (this.user.allPermissionsNames.includes('admin_users'))
    },
  },
  data() {
    return {
      permissionsLoading: false,
      PermissionForm: {
        permissions: [],
      },
      // Catalogs
      Permissions: [],
    }
  },
  methods: {
    getRole() {
      api.get(`/roles/${this.roleName}`).then(res => {
        const role = res.data.role
        this.PermissionForm.permissions = role.permissionNames
      })
    },
    setPermissionsToRole() {
      const request = {
        name: this.roleName,
        permissions: this.PermissionForm.permissions,
      }
      this.permissionsLoading = true
      api.put('/setPermissionsToRole', request)
        .then(response => {
          // Get the role  info
          const role = response.data.role
          const permissionNames = role.permissionNames
          // set the roles  in the form
          this.PermissionForm.permissions = permissionNames
          this.permissionsLoading = false
        })
        .catch(error => {
          console.log(error)
          this.permissionsLoading = false
        })
    },
    getPermissions() {
      api.get('/permissions').then(res => {
        this.Permissions = res.data.permissions
      })
    },
  },
  mounted() {
    this.getRole()
    this.getPermissions()
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
</style>
