<template>
  <div>
    <div class="air__utils__heading">
      <h5 v-if="role !== false">Perfil de {{role.display_name}}</h5>
      <h5 v-else>Nuevo Rol</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>

    <!--      Info Principal -->
    <div class="card p-4">
      <a-form-model
        class="mb-1"
        ref="rule-Main"
        :model="mainForm"
        :rules="mainFormRules"
        layout="vertical"
        :wrapper-col="{ span: 24}"
      >
        <a-form-model-item ref="display_name"  prop="display_name" label="Nombre">
          <a-input v-model.trim="mainForm.display_name" size="large" placeholder="Nombre"/>
        </a-form-model-item>

        <my-spinner :load="loading"/>

        <div class="form-actions mt-0" >
          <responsive-button @ClickEvent="onSubmit" pill responsive="md" text="Guardar" fontAwesome="fa fa-save" variant="primary" v-show="!loading && name ==='0'"/>
          <responsive-button @ClickEvent="onUpdate" pill responsive="md" text="Actualizar" icon="archive-fill" class="mx-2" fontAwesome="fa fa-save" variant="primary" v-show="!loading && role !== false"/>
          <responsive-button @ClickEvent="onDelete" pill responsive="md" text="Eliminar" icon="trash" variant="danger" class="mx-2" v-show="!loading && role !== false"/>
        </div>
      </a-form-model>
    </div>
    <!--      Info Principal -->

    <!--      Roles Y Permisos -->
    <role-permissions :roleName="name" v-if="name !== '0'"/>
    <!--      Roles Y Permisos -->

  </div>
</template>

<script>
import api from '@/services/axios/index'
import RolePermissions from '../components/RolePermissions'
import { Modal, notification } from 'ant-design-vue'
export default {
  name: 'registerEdit',
  components: {
    RolePermissions,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'admin_roles',
          breadcrumbName: 'Administración de Roles',
          aIcon: '',
        },
        {
          breadcrumbName: 'Detalle de Rol',
          aIcon: '',
        },
      ],
      name: this.$route.params.role,
      role: false,
      loading: false,
      mainForm: {
        display_name: '',
      },
      mainFormRules: {
        display_name: [
          { required: true, message: 'El Nombre es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'El Nombre no es válido.', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    getRole() {
      api.get(`/roles/${this.name}`).then(res => {
        const role = res.data.role
        this.role = role
        this.mainForm.display_name = role.display_name
      })
    },
    onSubmit() {
      this.$refs['rule-Main'].validate(valid => {
        if (valid) {
          this.loading = true
          api.post('/roles', {
            display_name: this.mainForm.display_name,
          })
            .then(response => {
              // Get the role and permission info
              const role = response.data.role
              this.role = role
              this.name = role.name
              this.$router.push({ name: 'detail_role', params: { role: role.name } })
              this.loading = false
            })
            .catch(() => {
              this.role = false
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este rol?',
        content: 'Todos los usuarios asignados a este rol perderán los permisos asociados.',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          api.delete(`/roles/${me.name}`)
            .then(response => {
              // Get the role and permission info
              me.loading = false
              me.$router.push({ name: 'admin_roles' })
            })
            .catch(() => {
              me.user = false
              me.loading = false
            })
        },
        onCancel() {
          notification.info({
            message: 'El usuario NO ha sido eliminado',
          })
        },
      })
    },
    onUpdate() {
      this.$refs['rule-Main'].validate(valid => {
        if (valid) {
          this.loading = true
          api.put(`/roles/${this.name}`, {
            name: this.name,
            display_name: this.mainForm.display_name,
          })
            .then(res => {
              // Get the role and permission info
              const role = res.data.role
              this.role = role
              this.mainForm.display_name = role.display_name
              this.loading = false
            })
            .catch(() => {
              this.role = false
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
  mounted() {
    if (this.name !== '0') {
      this.getRole()
    }
  },
}
</script>

<style scoped>

</style>
